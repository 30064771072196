//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
export default {
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return  {
      settings: {
        arrows: false,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 6000
      }
    }
  },
  components: {
    VueSlickCarousel,
  },
  methods: {
    next() {
      this.$refs.carousel.next()
    },
    prev() {
      this.$refs.carousel.prev()
    }
  }
}
