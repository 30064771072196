import { render, staticRenderFns } from "./CalloutSlider.vue?vue&type=template&id=2cd8a74e&"
import script from "./CalloutSlider.vue?vue&type=script&lang=js&"
export * from "./CalloutSlider.vue?vue&type=script&lang=js&"
import style0 from "./CalloutSlider.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/opt/build/repo/components/common/AppLink.vue').default,ArrowLeft: require('/opt/build/repo/components/icons/ArrowLeft.vue').default,ArrowRight: require('/opt/build/repo/components/icons/ArrowRight.vue').default})
