//
//
//
//
//

export default {
  props: {
    blok: {
      type: Array,
      default: () => []
    }
  }
}
